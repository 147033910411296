<template>
  <b-card>
    <h4 class="mb-0">
      Edit Mata Pelajaran
    </h4>
    <validation-observer ref="validUpdate">
      <b-form
        class="mt-1"
        @submit.prevent="validationForm"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Kode Mata Pelajaran"
              label-for="code"
            >
              <validation-provider
                #default="{ errors }"
                name="Kode Mata Pelajaran"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="dataStudent.code"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Kode Mata Pelajaran"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Nama Mata Pelajaran"
              label-for="subject"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Lengkap"
                rules="required"
              >
                <b-form-input
                  id="subject"
                  v-model="dataStudent.subject"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nama Mata Pelajaran"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Aktif"
              label-for="active"
            >
              <validation-provider
                #default="{ errors }"
                name="Aktif"
                rules="required"
              >
                <b-form-checkbox
                  id="active"
                  v-model="dataStudent.active"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Aktif"
                  value="1"
                  unchecked-value="0"
                  switch
                  inline
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Deskripsi"
              label-for="description"
            >
              <validation-provider
                #default="{ errors }"
                name="Deskripsi"
                rules="required"
              >
                <b-form-input
                  id="description"
                  v-model="dataStudent.description"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Deskripsi"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mr-1"
              @click="() => $router.go(-1)"
            >
              Cancel
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
            >
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'

export default {
  name: 'DataSiswaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BContainer,
    BFormCheckbox,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    required,
    name: '',
    dataStudent: {
      code: '',
      subject: '',
      active: 0,
      description: '',
    },
    locations: {
      province: '',
      city: '',
      district: '',
      subdistrict: '',
    },
    dataIsActive: [
      { value: 1, text: 'Aktif' },
      { value: 0, text: 'Tidak Aktif' },
    ],
  }),
  computed: {
    filterCities() {
      return this.dataCities.filter(
        item => item.parent === this.locations.province.wilayah_id,
      )
    },
    filterDistrics() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.city.wilayah_id,
      )
    },
    filterSubDistrics() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.distric.wilayah_id,
      )
    },
  },
  created() {
    this.getStudentDetail()
  },
  methods: {
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/academicsubjects/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    fillStudentData(data) {
      this.dataStudent.code = data.code
      this.dataStudent.subject = data.subject
      this.dataStudent.active = data.active
      this.dataStudent.description = data.description
    },
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            // Object.keys(this.locations).forEach(key => {
            //   dataFormStudent.append(key, this.locations[key])
            // })
            dataFormStudent.append('_method', 'PUT')
            await this.$http.post(`/academicsubjects/${studentId}`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tambah Data Mata Pelajaran Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'mata-pelajaran' })
          } catch (err) {
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tambah Data Mata Pelajaran Tidak Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            console.log(err)
          }
        }

        // function alertnya() {
        //   alert('form submitted!')
        // }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
